@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@400;500;700&display=swap');

* {
  font-family: 'prompt' ,sans-serif;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



.cookie-section {
    background-color: #0a0066e1;
      min-height: 5vh !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
      /* font-size: calc(10px + 2vmin); */
      color: rgb(255, 255, 255);
      z-index:1000;
      position:fixed;
      width: 100%;
      bottom:0;
      right:0;
      
}

.Logo {
  width:125px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom css Begin Here */

.arit-bg {
  background-color: #001c54;
}

.main-nav {
  z-index:6000;
}

.navbar-nav li:hover>ul.dropdown-menu {
  display: block;
}

.welcome-img {
  position:relative;
  margin-top:150px;
}

.begin-top {
  margin-top: 150px;
}

.google-nav {
  position:-webkit-sticky;
  position:sticky;
  top:100px;
  z-index:1000;
  background-color: rgba(242, 242, 242, 0.8);
  

}


.img-meet {
  margin-bottom: 2rem;
  transition: all .3s ease-in-out;
  
}

.img-meet:hover{
  cursor: pointer;
  transform: scale(1.1);
  
  
}




.hover-underline-animation {
  display: inline-block;
  position: relative;
  /* color: #0087ca; */
  color:#001c54;
}

.google-nav ul li  a {
  /* color:white; */
  color:#282c34;
  transition: .3s ease-in;
}

.google-nav ul li  a:hover {
  color:#282c34;
  /* color:rgb(255, 223, 42); */
}

.top {
  position:fixed;
  bottom: 100px;
  right:20px;
  background-color: #fec006;
  border-radius: 50%;
  display: flex;
  justify-self: center;
  align-items: center;
  font-size:35px;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  /* background-color: #0087ca; */
  background-color: #001c54;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}


.support {
  margin-top:150px;
}

/* #footer {
  position: fixed;
  bottom:0;
  left:0;
  width:100%;
  
} */

.support-card {
  width:18rem;
  align-items:center;
  transition: 0.4s ease-in-out;
  
}


i {
  margin:2px;
  /* color:#001c54; */
}
.support-card:hover {
  position:relative;
  
  cursor: pointer;
  transform: scale(1.1);
 
  
}

.img-hover {
  border-radius: 20px;
  
  transition: .3s ease-in-out;
  box-shadow: 0 0 10px #f3f3f3;
}

.img-hover:hover{
  cursor: pointer;
  transform: scale(1.1);
}

.sidebar {
  width:100vh;
  background-color:#282c34;
  color:aliceblue;
  height:100vh;
  position:fixed;
  display:block;
  top:0;
  left:0;
  position:fixed;
  z-index:-10;
}

.cropped1 {
  width: 384px; /* width of container */
  height: 319px; /* height of container */
  object-fit: cover;
  /* border: 5px solid black; */
}

@media (max-width:1000px) {
  .nav-sub {
   
    display:none !important;
    position:fixed relative;
    
  }
  
}

@media (max-width: 768px) {
  .support-card {
    width:100%;
  }

    .img-popup {
      width:80%;
    }
 

  a.navbar-brand {
    font-size:14px;
    display:flex;
    align-items: center;
    /* padding:2rem; */
  }

  .Logo{
    width:80px;
  }
  .top {
    z-index:8000;
    bottom: 10px;
  }

  #youtube-doc {
    width:80vw;
  }

  .nav-sub {
    position:fixed relative;
    /* top: 230px; */
    width:100vw;
    height:auto;
    margin:auto;
    background:#001c5463;
  }

  i.fa-solid.fa-circle-right {
    display: none;
  }
  /* <i class="fa-solid fa-circle-right"></i> */
  /* .begin-top {
    height:450px;
  } */

  .begin-top {
    display:flex;
    justify-content: center;
    align-items: center;
    height:100vh;
    padding-top:5em;
    /* padding:1rem 1rem 0rem 2rem; */
  }

  .Logo {
    /* display:none; */
  }

  #footer {
    position:relative;
    bottom:0;
    left:0;
    /* padding-top: 1rem; */
    
  }

  #google-meet {
    /* display: block !important; */
    padding:0em;
    justify-content: center;
    /* background-color: #001c54 !important; */
    
  }

  /* * {
    font-size:13px;
  } */

 iframe {
   width:100%;
 }
 

  .google-meet-card {
    padding:20px !important;
    margin:20px !important;
  }

  h5 {
    font-size: 5px;
  }

  .begin-top {
    margin-top:2rem;
  }
  
  
}